<template functional>
    <h2 :class="props.qaClass">{{ props.subtitle }}</h2>
</template>

<script>
export default {
	name: 'SubtitleText',
	props: {
		subtitle: {
			type: String,
			default: null
        }, 
        qaClass: {
            type: String,
			default: null
        }
	}
}
</script>
